export function getGraphqlBase(host: string) {
    const [hostOnly, _port] = host.split(":");
    const [first, ...rest] = host.split(".");

    if (hostOnly === "localhost" || domainMatches(hostOnly, "3nv.io")) {
        // "/graphql/" path suffix style
        return `${host}/graphql/`;
    } else if (first.startsWith("test") || first.startsWith("demo") || hostOnly === "cloud.web1.fi") {
        // "graphql.<host>" style
        return `graphql.${host}`;
    }

    // Strip the first part of the hostname, "graphql.<base_host>" style
    return `graphql.${rest.join(".")}`;
}

function domainMatches(host: string, domain: string) {
    return host === domain || host.endsWith(`.${domain}`);
}
